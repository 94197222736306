function floating() {
  let timeoutId;
  const floatingBanner = document.querySelector('.js-floating');

  window.addEventListener('scroll', function () {
    floatingBanner.classList.remove('is-active');

    clearTimeout(timeoutId);

    timeoutId = setTimeout(function () {
      floatingBanner.classList.add('is-active');
    }, 1500);
  });
}

floating();
